import * as React from "react";
import Blogs from "../components/blogs.jsx"
import POSTS from "../data/posts.json";




import "../styles/blog.css"

console.log(POSTS);


export default function Blog() {
  
  
  
  return (
    <>
      <title>Blog</title>
      <div className="hero-image blog-hero">
        <div className="hero-text">
          <h1 className="hero-title">Blog</h1>
          <h3 id="description">Keep up with everything goin' on with me, my projects, and any new music releases.</h3>
        </div>
      </div>
      <div className="blog">
        <Blogs data={POSTS}/>
      </div>
    </>
  );
}
