import * as React from "react";

export default class TrackList extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.data);
        this.state = {
        tracks: this.props.data
        }
    }


    
    render() {
        //convert dates into timestamps
        this.state.tracks.forEach((track) => {
            console.log(track.date)
            console.log(new Date(track.date).toLocaleDateString())
            track.date = new Date(track.date).toLocaleDateString();

        })
        //sort tracks by date
        this.state.tracks.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        })
        console.log("sorted tracks")
        console.log(this.state.tracks)
        
        return (
        <div className="audio-container">
            {this.state.tracks.map((track, index) => {
            return (
                <>
                <div className={`audio-item cover ${index % 2 === 0 ? "even" : "odd"}`}>
                    <img
                    className="cover"
                    src={track.cover}
                    />
                </div>
                <div id={track.id} className={`audio-item ${index % 2 === 0 ? "even" : "odd"}`}>
                    <div className="credits">
                    <span className="title">{track.title}</span><br />{track.date}<br /><span className="credits">{track.credits}</span>
                    </div>
                    <audio controls>
                    <source
                        src={track.audio}
                        type="audio/mp3"
                    />
                    </audio>
                </div>
                </>
            )
            })}
        </div>
        )
    }

}
