import * as React from "react";
import Tracklist from "../components/tracklist.jsx";

import TRACKS from "../data/tracks.json"


import "../styles/music.css";

console.log(TRACKS);

export default function Music() {
    return (
        <>
                      <h1 id="title">🎵 Tunes 🎵</h1>
      <h3 id="description">
        All my music, available to stream and download. Interested in using my
        music? Shoot me an <a href="mailto:thelittledocplays+music@gmail.com">email!</a>
      </h3>

        <Tracklist data ={TRACKS} />
                </>
    );
}