import * as React from "react";


export default class Logo extends React.Component {

    render() {
        let data = this.props.data;
        //sort by key
        //extract keys
        let dates = Object.keys(data);
        var todayLogo = "logo512.png";
        for(let i = 0; i < dates.length; i++) {
            let today = new Date();
            today.setHours(0,0,0,0)
            let date = new Date(dates[i]);
            if(today.toDateString() === date.toDateString()) {
                todayLogo = Object.values(data)[i].file;
            
            } else {
                console.log("not found");
            }
        }
        return(
            <div className="logo-container">
                <div className="logo">
                    <img className="logoNav" src={todayLogo} />
                </div>
            </div>

        );
        }

}