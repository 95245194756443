import React from "react";
import Logo from "../components/holiday.jsx";
import { Outlet, Link } from "react-router-dom";


import Holidays from "../data/holidays.json";

const Layout = () => {
  return (
    <>
      <div className="callout">
        <h5 className="calloutHeadline"><b>Attention!</b> Datastream is now accessible from a new address: <em>https://www.datastream.page</em></h5>
        <h5 className="calloutSubheading">Thank you for bearing with us as we begin the transition to our new host!</h5>
      </div>
      <nav id="navbar">
      <ul className="nav">
        <Link to="/">
        <li className="logoNav">
          <Logo data={Holidays} />
        </li>
          </Link>
        <li className="about"><Link to="/about">About</Link></li>
        <li className="blog"><Link to="/blog">Blog</Link></li>
        <li className="projects"><Link to="/projects">Projects</Link></li>
      </ul>
    </nav>

    <div className="main">
      <Outlet />
    </div>
    <div className="footer">
      <p className="footer">
        All content © 2020-2023 TheLittleDoctor unless otherwise noted
      </p>
    </div>
    </>
  )
};

export default Layout;

