import * as React from "react";
import { findDOMNode } from "react-dom"

export default function Home() {

  return (
    <>
      <script>

      </script>
      <h1 id="tile">Datastream</h1>
      <h3 id="description">
        A blog demonstrating 100% accurately everything that pops into my head
      </h3>
      <h2 id="whoAmI">
        Who am I?
      </h2>
      <p>
        Great question! I'm TheLittleDoctor, and I'm a <strong>musician</strong>, <strong>programmer</strong>, and an <strong>electronics</strong> enthusiast. I've spent thousands of hours over the last several years exploring and creating in virutal reality, and now as I'm preparing to study computer science in the fall, I'm hoping to begin work on a VR experience of my own.
      </p>
      <p>
        Here, I'll write about projects I'm working on, things I'm learning, and anything else that comes to mind. You'll see some of my music, a few commentary pieces on the happenings in tech, and possibly some rants about "bike lanes" and "high-speed rail" and the like. I started this blog in the fall of 2020, and it's refreshing to have rebuilt it from the ground up in a way that is <em>sooo</em> much easier to write for! Stay tuned for all my future shenanigans, and thanks for dropping by!
      </p>

      <span id="mastodon">
        <a rel="me" href="https://voring.me/@elysium">Mastodon</a>
      </span>
    </>
  );
  
}


