import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { findDOMNode } from "react-dom"
import Layout from "./pages/layout.jsx";
// Import the Home page component
import Home from "./pages/home.jsx";
import About from "./pages/about.jsx";
import Blog from "./pages/blog.jsx";
import NoPage from "./pages/nopage.jsx";
import Links from "./pages/links.jsx";
import Music from "./pages/music.jsx";

// Import the Helmet
import { Helmet } from "react-helmet";

// Import and apply CSS stylesheet
import "./styles/styles.css";
import "./styles/temp.css";
import Projects from "./pages/projects.jsx";

const TITLE = "Datastream";
export default class App extends React.Component {
  render () {
    return (
      <>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/music" element={<Music />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      </>
    );
  }
}