import * as React from "react";
import { useEffect } from "react";

var i = 0;
var speed = 200; /* The speed/duration of the effect in milliseconds */

var displayText = "";


function typeWriter(txt, ignore) {
  console.log(txt)
  ignore = ignore;
  console.log(ignore);
  if (i < txt.length) {
    displayText += txt.charAt(i);
    document.getElementById("title").innerHTML = displayText;
    i++;
    setTimeout(typeWriter, (Math.floor(Math.random() * 150) + 60), txt, ignore);
  } else if (ignore === true) {
    return;
  } else {
    
    flashB();
    ignore = true;
    return ignore;
  }
}


function flashA() {
  displayText = displayText.slice(0, -1)
  document.getElementById("title").innerHTML = displayText;
  setTimeout(flashB, 500)
}

function flashB() {
  displayText += "|"
  document.getElementById("title").innerHTML = displayText;
  setTimeout(flashA, 500)
}



var header = document.getElementById("navbar");


export default function About() {
  useEffect(() => {
    let ignore = false;
    
    if (!ignore) {
      // ignore = typeWriter("TheLittleDoctor", ignore);
      console.log(ignore);
    }
  },[]);
  return (
    <>
      <h1 id="title">TheLittleDoctor</h1>
      <h3 id="description">
        VR Enthusiast, programmer, and bug-finder supreme
      </h3>
      <h2 id="whoAmI">Who am I?</h2>
      <p>
        Great question! I'm TheLittleDoctor, a VR enthusiast and programmer, an
        avid Anyland player, and bug-finder supreme.
        <br />
        Seriously, sometimes I feel like all I do is find bugs for people.
      </p>

      
    </>
  );
}
