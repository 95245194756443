import * as React from "react";

export default function Projects() {
    return (
        <>
        <h1 id="title">Active Projects</h1>
      <h3 id="description">
        Looking for previous projects? Sorry, you're outta luck right now...<br />
        
      </h3>
      <div className="article">
        <h6 className="dateline">September, 2020 -</h6>
        <h2 className="post-title">The Atari 2600 repair and digitization</h2>
        <p className="description">
          I purchased 3 Atari 2600s of different varieties from eBay in
          September of 2020, here's what I plan to do with them:<br /><b
            >Repair</b
          ><br />All three units are suffering from some kind of power supply
          issues. I suspect that at least one of them has a bad voltage
          regulator, and the other two could just have a bad connection on the
          power jack. Regardless, all three units will need to have repairs made
          to allow them to start. In addition, all three need a good deep
          cleaning, and one of them will require a minor repair to the rear I/O
          cover. Not a whole lot to do, but I'll be updating y'all when I make
          some progress.<br /><b>Documentation & digitization</b><br />Atari
          2600 schematics are many in numbers, but quality ranges from somewhat
          legible though pixelated to literally dipped in a coffee mug and hung
          out to dry. One of my main goals going through this is to produce a
          scalable version of the schematics for each of the three revisions of
          system I own. In addition, I'll be attempting to produce a style guide
          and manufacturing plans for the commonly broken parts of the unit,
          such as the ribs and vents on the top of the unit or the various foam
          gaskgets that sit behind the switches. All of these plans will
          eventually be posted here on my site at a future date.
        </p>
        <button className="article">
          Unavailable
        </button>
      </div>
      <div className="article">
        <h6 className="dateline">December, 2020</h6>
        <h2 className="post-title">BetterDiscord AMOLED Theme</h2>
        <p className="description">
          For those of you 'in the loop' with Discord Android, some of you may
          have noticed a few updates ago the ability was added to toggle a
          "Super Dark Mode (AMOLED)". Having now used it for so long, I've been
          missing it on desktop, so I've made it here! I suspect at some point
          I'll have to add a whole downloads section to my projects, but for
          now, I'm just gonna release my themes and such as they get finished.
          Without further ado, here is the <b>Discord AMOLED</b> theme for
          desktop mode.
          <i>Please note, all my themes will require BetterDiscord.</i>
        </p>
        <a href="/downloads/AMOLED.theme.css" download>
          <button className="download">
            Download Discord AMOLED theme
          </button>
        </a>
      </div>
      </>
      )
}